<template>
    <div>
        <ElForm class="login-form">
            <h3 class="title33">{{ $t('TEXT0096') }}</h3>
            <el-form-item :error="input.error.email">
                <el-input
                        v-model="input.model.email"
                        maxlength="128"
                        size="large"
                        class="fa-imput-icon"
                        :placeholder="$t('TEXT0019')">
                    <i class="fa fa-envelope" slot="prefix"/>
                </el-input>
            </el-form-item>
            <ElButton type="primary"
                      :loading="input.data.resetting"
                      @click.prevent="resetId">{{ $t('TEXT0034') }}
            </ElButton>
        </ElForm>
        <SendMailSuccessDialog
                :title="$t('TEXT0036')"
                :message="$t('TEXT0108')"
                :okText="$t('TEXT0038')"
                @ok="$router.push('/login')"
                v-model="popup.successMessage.show"/>

        <!--        <SuccessDialog-->
        <!--                v-model="popup.successMessage.show"-->
        <!--                title=""-->
        <!--                :message="popup.successMessage.data"-->
        <!--                @ok="$router.push('/login')"/>-->
    </div>
</template>

<script>
import GuestApi from "@/api/GuestApi";
import EventBus from "@/config/EventBus";
import SendMailSuccessDialog from "@/components/SendMailSuccessDialog";

export default {
    name: "IdReset",
    components: {SendMailSuccessDialog},
    mounted() {
        EventBus.$on('localeChange', () => {
            if (this.$route.path == '/id/reset') {
                this.setNull(this.input.error)
            }
        })
    },
    data() {
        return {
            input: {
                model: {
                    email: null,
                },
                error: {
                    email: null,
                },
                data: {
                    resetting: false
                }
            },
            loading: {
                reset: false
            },
            popup: {
                successMessage: {
                    data: null,
                    show: false
                }
            }
        }
    },
    methods: {
        resetId: function () {
            this.setNull(this.input.error)
            this.input.data.resetting = true
            GuestApi.resetId(this.trimObject(this.input.model)).then(resp => {
                console.log(resp)
                this.popup.successMessage.show = true
                this.popup.successMessage.data = this.$t('TEXT0097')
            }).catch(resp => {
                if (resp.status == 412) {
                    for (let item of resp.data) {
                        this.input.error[item.key] = item.value
                    }
                }
            }).finally(() => {
                this.input.data.resetting = false
            })
        }
    }
}
</script>

<!--<style scoped lang="scss">-->
<!--@import "./src/style/variables.scss";-->

<!--.login-form {-->
<!--    padding: 20px 32px;-->
<!--    background-color: white;-->
<!--    margin: calc((100vh - 425px) / 2 - 50px) auto;-->
<!--    width: map-get($loginForm, width);-->
<!--    min-width: map-get($loginForm, minWidth);-->
<!--}-->

<!--.login-form h3 {-->
<!--    text-align: center;-->
<!--}-->

<!--.login-form button {-->
<!--    width: 100%;-->
<!--    margin-bottom: 20px;-->
<!--}-->

<!--::v-deep .el-input&#45;&#45;prefix .el-input__inner {-->
<!--    padding-left: 40px;-->
<!--}-->

<!--::v-deep .fa-imput-icon .el-input__prefix {-->
<!--    font-size: 20px;-->
<!--    left: 12px;-->
<!--    top: 0px;-->
<!--}-->

<!--</style>-->
